import { EMealPlanType } from "../types/mealPlan.type";

const INITIAL_STATE = {
  plans: [],

  loadingPlans: false,

  errorGetPlans: null,
};

export default (state = INITIAL_STATE, { type, playload }: any) => {
  switch (type) {
    case EMealPlanType.getPlans:
      return {
        ...state,
        plans: playload,
        loadingPlans: false,
      };
    case EMealPlanType.loadingPlans:
      return {
        ...state,
        loadingPlans: true,
        errorGetPlans: null,
      };
    case EMealPlanType.errorGetPlans:
      return {
        ...state,
        loadingPlans: false,
        errorGetPlans: playload,
      };
    default:
      return state;
  }
};

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import userReducer from "./user.reducer";
import routineReducer from "./routine.reducer";
import mealPlanReducer from "./mealPlan.reducer";
import tipReducer from "./tip.reducer";
import notificationsReducer from "./notifications.reducer";

export default combineReducers({
  form: formReducer,
  userReducer,
  routineReducer,
  mealPlanReducer,
  tipReducer,
  notificationsReducer,
});

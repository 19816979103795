import * as React from "react";
import style from "./loadingSuspense.module.scss";

export const LoadingSuspense: React.FC = () => {
  return (
    <div className={style.loadingContainer}>
      <img
        src={process.env.PUBLIC_URL + "/assets/images/splash.png"}
        alt="loading"
        className={style.splash}
      />
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Routes } from "./Routes";
import { connect } from "react-redux";
import { getCurrentUser } from "./redux/actions/user.action";
import { socket } from "./socket";

import "semantic-ui-css/semantic.min.css";
import { EStorage } from "./enums/storage.enum";

import { pdfjs } from "react-pdf";
import { ESocket } from "./enums/socket.enum";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const App = (props: any) => {
  useEffect(() => {
    const userStorage: string | null = localStorage.getItem(EStorage.user);
    props.getCurrentUser(userStorage ? JSON.parse(userStorage) : null);
    
    // socket.connect();

    // socket.on(ESocket.connected, (socketId) =>
    //   localStorage.setItem(EStorage.socketId, socketId)
    // );

    // return () => {
    //   socket.emit(ESocket.disconnected, JSON.parse(userStorage).idUser);
    //   socket.disconnect();
    // };
  }, []);

  return <Routes />;
};

const mapStateToProps = ({ userReducer }: any) => ({ userReducer });

const mapDispatchToProps = {
  getCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

export enum ETipType {
  getTips = "GET_TIPS",
  getMyths = "GET_MYTHS",
  getTechniques = "GET_TECHNIQUES",
  getTraining = 'GET_TRAINING',
  getSupplementation = 'GET_SUPPLEMENTATION',
  getKnowledge = 'GET_KNOWLEDGE',

  loadingTips = "TIPS_LOADING",

  errorTips = "ERROR_TIPS",
}

import { ETipType } from "../types/tip.type";

const INITIAL_STATE = {
  tips: [],
  myths: [],
  techniques: [],
  knowledge: [],
  supplementation: [],
  training: [],

  loadingTips: false,

  errorTips: null,
};

export default (state = INITIAL_STATE, { type, playload }: any) => {
  switch (type) {
    case ETipType.getTips:
      return {
        ...state,
        tips: playload,
        loadingTips: false,
      };
    case ETipType.loadingTips:
      return {
        ...state,
        loadingTips: true,
        errorTips: null,
      };
    case ETipType.errorTips:
      return {
        ...state,
        errorTips: playload,
        loadingTips: false,
      };

    case ETipType.getMyths:
      return {
        ...state,
        myths: playload,
        loadingTips: false,
      };

    case ETipType.getTechniques:
      return {
        ...state,
        techniques: playload,
        loadingTips: false,
      };

    case ETipType.getKnowledge:
      return {
        ...state,
        knowledge: playload,
        loadingTips: false,
      };

    case ETipType.getSupplementation:
      return {
        ...state,
        supplementation: playload,
        loadingTips: false,
      };

    case ETipType.getTraining:
      return {
        ...state,
        training: playload,
        loadingTips: false,
      };

    default:
      return state;
  }
};

import { IUser } from "../interfaces/user.interface";

export class MUser {
  public idUser: number;
  public name: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public age: number;
  public equipment: null | string;
  public injuries: null | string;
  public diseases: null | string;
  public nuisance: null | string;
  public notes: null | string;
  public updateDate: Date;
  public registerDate: Date;
  public gender: "M" | "F";
  public height: number;
  public weight: number;
  public role: "OWNER" | "CLIENT" | "ADMIN";
  public photo: null | string;

  constructor(user: IUser) {
    this.idUser = user.idUser;
    this.name = user.name;
    this.lastName = user.lastName;
    this.email = user.email;
    this.phone = user.phone;
    this.age = user.age;
    this.equipment = user.equipment;
    this.injuries = user.injuries;
    this.diseases = user.diseases;
    this.nuisance = user.nuisance;
    this.notes = user.notes;
    this.updateDate = new Date(user.updateDate);
    this.registerDate = new Date(user.registerDate);
    this.gender = user.gender;
    this.height = user.height;
    this.weight = user.weight;
    this.role = user.role;
    this.photo = user.photo;
  }
}

import { ERoutineType } from "../types/routine.type";

const INITIAL_STATE = {
  routines: [],

  errorRoutines: null,

  loadingRoutines: false,
};

export default (state = INITIAL_STATE, { type, playload }: any) => {
  switch (type) {
    case ERoutineType.getRoutines:
      return {
        ...state,
        routines: playload,
        loadingRoutines: false,
      };
    case ERoutineType.loadingGetRoutines:
      return {
        ...state,
        loadingRoutines: true,
        errorRoutines: null,
      };
    case ERoutineType.errorGetRoutines:
      return {
        ...state,
        loadingRoutines: false,
        errorRoutines: playload,
      };
    default:
      return state;
  }
};

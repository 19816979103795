import { ENotificationsTypes } from "../types/notifications.type";

const INITIAL_STATE = {
  notifications: [],

  loadingNotification: false,

  errorNotifications: null,
};

export default (state = INITIAL_STATE, { playload, type }: any) => {
  switch (type) {
    case ENotificationsTypes.getNotifications:
      return {
        ...state,
        notifications: playload,
        loadingNotification: false,
      };

    case ENotificationsTypes.loadingGetNotifications:
      return {
        ...state,
        loadingGetNotifications: true,
        errorNotifications: playload,
      };

    case ENotificationsTypes.errorGetNotifications:
      return {
        ...state,
        errorGetNotifications: playload,
        loadingGetNotifications: false,
      };

    default:
      return state;
  }
};

import axios from "axios";
import { config } from "./config";
import { EStorage } from "./enums/storage.enum";

const { baseURL } = config;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(async (request) => {
  try {
    const token = localStorage.getItem(EStorage.token);

    if (token) {
      request.headers.Authorization = token;
    }
    return Promise.resolve(request);
  } catch (error) {
    console.log("[Request Error]: ", error);
    return Promise.reject(error);
  }
});

axiosInstance.interceptors.response.use(
  async (response) => {
    if (response.headers.token) {
      localStorage.removeItem(EStorage.token);
      localStorage.setItem(EStorage.token, response.headers.token);
    }
    return Promise.resolve(response);
  },
  (error: any) => {
    // if (error.response.status === 401) {
    //   localStorage.removeItem(EStorage.token);
    //   localStorage.removeItem(EStorage.user);
    //   window.location.href = "https://app.streetgymprogram.com/";
    // }

    console.log("[Response Error]: ", error.response);
    return Promise.reject(error);
  }
);

export default axiosInstance;

export enum EUserType {
  loginUser = "LOGIN_USER",
  changePhoto = "CHANGE_USER_PHOTO",
  changePassowrd = "CHANGE_PASSWORD",

  loadingLoginUser = "LOADING_LOGIN_USER",
  loadingChangePhoto = "LOADING_CHANGE_USER_PHOTO",
  loadingChangePassword = "LOADING_CHANGE_PASSWORD",

  errorLoginUser = "ERROR_LOGIN_USER",
  errorChangePhoto = "ERROR_CHANGE_USER_PHOTO",
  errorChangePassword = "ERROR_CHANGE_PASSWORD",

  setCurrentUser = "SET_CURRENT_USER",
  logout = "LOGOUT",
}

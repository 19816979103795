import React, { FC, lazy, Suspense } from "react";
import { Router, Redirect } from "@reach/router";
import { EStorage } from "./enums/storage.enum";

import { LoadingSuspense } from "./components/LoadingSuspense";

const Auth = lazy(() => import("./pages/Auth"));
const Home = lazy(() => import("./pages/Home"));
const Routines = lazy(() => import("./pages/Routines"));
const Nutrition = lazy(() => import("./pages/Nutrition"));
const Tips = lazy(() => import("./pages/Tips"));
const Training = lazy(() => import("./pages/Training"));
const Myths = lazy(() => import("./pages/Myths"));
const Profile = lazy(() => import("./pages/Profile"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Notifications = lazy(() => import("./pages/Notifications"));
const Knowledge = lazy(() => import('./pages/Knowledge'));
const supplementation = lazy(() => import('./pages/Supplementation'));
const TipTraining = lazy(() => import('./pages/TipTraining'));

export const Routes: FC = (props: any) => (
  <Suspense fallback={<LoadingSuspense />}>
    <Router basepath="/">
      <SessionRoute path="/" component={Home} />
      <AuthRoute path="/auth" component={Auth} />
      <SessionRoute path="/routines" component={Routines} />
      <SessionRoute path="/nutrition" component={Nutrition} />
      <SessionRoute path="/tips" component={Tips} />
      <SessionRoute path="/training" component={Training} />
      <SessionRoute path="/myths" component={Myths} />
      <SessionRoute path="/profile" component={Profile} />
      <SessionRoute path="/notifications" component={Notifications} />
      <SessionRoute path="/knowledge" component={Knowledge} />
      <SessionRoute path="/supplementation" component={supplementation} />
      <SessionRoute path="/tips-training" component={TipTraining} />
      <NotFound path="*" />
    </Router>
  </Suspense>
);

const AuthRoute = ({ component: Component, path, ...rest }: any) => {
  const user = localStorage.getItem(EStorage.user);
  return !user ? (
    <Component {...rest} />
  ) : (
    <Redirect to="/" noThrow={true} replace={true} />
  );
};

const SessionRoute = ({ component: Component, path, ...rest }: any) => {
  const user = localStorage.getItem(EStorage.user);
  return user ? (
    <Component {...rest} />
  ) : (
    <Redirect to="/auth" noThrow={true} replace={true} />
  );
};

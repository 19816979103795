import { EUserType } from "../types/user.type";

const INITIAL_STATE = {
  currentUser: null,

  loadingLogin: false,
  loadingChangePhoto: false,
  loadingChangePassword: false,

  errorLogin: null,
  errorChangePhoto: null,
  errorChangePassword: null,
};

export default (state = INITIAL_STATE, { type, playload }: any) => {
  switch (type) {
    case EUserType.loginUser:
      return {
        ...state,
        currentUser: playload,
        loadingLogin: false,
      };
    case EUserType.loadingLoginUser:
      return {
        ...state,
        loadingLogin: true,
        errorLogin: null,
      };
    case EUserType.errorLoginUser:
      return {
        ...state,
        loadingLogin: false,
        errorLogin: playload,
      };
    case EUserType.setCurrentUser:
      return {
        ...state,
        currentUser: playload,
      };
    case EUserType.logout:
      return {
        ...state,
        currentUser: null,
      };

    case EUserType.changePhoto:
      return {
        ...state,
        loadingChangePhoto: false,
      };

    case EUserType.loadingChangePhoto:
      return {
        ...state,
        loadingChangePhoto: true,
        errorChangePhoto: null,
      };

    case EUserType.errorChangePhoto:
      return {
        ...state,
        loadingChangePhoto: false,
        errorChangePhoto: playload,
      };

    case EUserType.changePassowrd:
      return {
        ...state,
        loadingChangePassword: false,
      };

    case EUserType.loadingChangePassword:
      return {
        ...state,
        loadingChangePassword: true,
        errorChangePassword: null,
      };

    case EUserType.errorChangePassword:
      return {
        ...state,
        loadingChangePassword: false,
        errorChangePassword: playload,
      };

    default:
      return state;
  }
};

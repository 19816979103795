import axios from "../../axios";
import { Dispatch } from "redux";
import { EUserType } from "../types/user.type";
import { LoginParamsType } from "../../types/loginParams.type";
import { EStorage } from "../../enums/storage.enum";
import { RequestRegisterType } from "../../types/requestRegister.type";
import { createActivity } from "../../utils/activity";
import { EActivityType } from "../../enums/activityType.enum";
import { MUser } from "../../models/user.model";
import cogoToast from "cogo-toast";
import { ChangePasswordType } from "../../types/changePassword.type";
import { sendSubscription } from "../../../subscription";
import { socket } from "../../socket";
import { ESocket } from "../../enums/socket.enum";
import { ISocketConnection } from "../../interfaces/socketConnection.interface";

/**
 * @description Obtiene al usuario actualizado
 */
export const getCurrentUser = (user: MUser | null) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: EUserType.setCurrentUser,
    playload: user,
  });

  if (!user) {
    localStorage.removeItem(EStorage.user);
    return;
  }

  const { data } = await axios.get(`/user/${user.idUser}`);

  const newUser: MUser = new MUser(data.data);
  // socket.emit(ESocket.connectUser, {
  //   idUser: newUser.idUser,
  //   socketId: localStorage.getItem(EStorage.socketId),
  // } as ISocketConnection);
  localStorage.setItem(EStorage.user, JSON.stringify(newUser));

  // sendSubscription();

  dispatch({
    type: EUserType.setCurrentUser,
    playload: newUser,
  });
};

/**
 * @description Cambia la contraseña de un usuario
 */
export const changeUserPassword = ({
  currentPassword,
  newPassword,
}: ChangePasswordType) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: EUserType.loadingChangePassword,
    });

    const currentUser: MUser = JSON.parse(localStorage.getItem(EStorage.user));

    await axios.patch(`/user/passwordClient/${currentUser.idUser}`, {
      currentPassword,
      newPassword,
    });

    dispatch({
      type: EUserType.changePassowrd,
    });

    cogoToast.success("Contraseña actualizada exitosamente");
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: EUserType.errorChangePassword,
      playload: error.response.data,
    });
  }
};

/**
 * @description Cambia la foto de perfil del usuario
 */
export const changeUserPhoto = (photo: File) => async (
  dispatch: Dispatch,
  getState: any
) => {
  try {
    dispatch({
      type: EUserType.loadingChangePhoto,
    });

    const formData = new FormData();
    formData.append("photo", photo);

    const user: MUser = getState().userReducer.currentUser;
    const { data } = await axios.patch(`/user/photo/${user.idUser}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const userUpdate: MUser = new MUser(data.data);
    localStorage.setItem(EStorage.user, JSON.stringify(userUpdate));

    dispatch({
      type: EUserType.setCurrentUser,
      playload: userUpdate,
    });

    dispatch({ type: EUserType.changePhoto });
    cogoToast.success("Actualización exitosa");
  } catch (error) {
    console.error(error.response);
    dispatch({
      type: EUserType.errorChangePhoto,
      playload: error.response,
    });
  }
};

/**
 * @description Sete al usuario actual
 */
export const setCurrentUser = (currentUser: MUser) => (dispatch: Dispatch) => {
  dispatch({
    type: EUserType.setCurrentUser,
    playload: currentUser,
  });
};

/**
 * @description Envia una solicitud de registro
 */
export const sendRequestRegister = async (
  params: RequestRegisterType
): Promise<boolean> => {
  try {
    const activity = createActivity(EActivityType.requestRegister);
    await axios.post(`/user/sendRegister?activity=${activity}`, params);

    return Promise.resolve(true);
  } catch (error) {
    console.error(error.response);
    return Promise.reject(error.response);
  }
};

/**
 * @description Loguea a un usuario
 */
export const loginUser = (params: LoginParamsType) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({
      type: EUserType.loadingLoginUser,
    });

    const { data } = await axios.post("/user/login/client", params);
    const user: MUser = new MUser(data.data);

    localStorage.setItem(EStorage.user, JSON.stringify(user));
    // socket.emit(ESocket.connectUser, {
    //   idUser: user.idUser,
    //   socketId: localStorage.getItem(EStorage.socketId),
    // } as ISocketConnection);

    // sendSubscription();

    dispatch({
      type: EUserType.loginUser,
      playload: user,
    });
  } catch (error) {
    console.error(error.response);
    dispatch({
      type: EUserType.errorLoginUser,
      playload: error.response ? error.response.data : error,
    });
  }
};

/**
 * @description desloguea a un usuario
 */
export const logout = () => (dispatch: Dispatch) => {
  const userStorage: MUser = JSON.parse(localStorage.getItem(EStorage.user));
  // socket.emit(ESocket.disconnected, userStorage.idUser);
  localStorage.removeItem(EStorage.token);
  localStorage.removeItem(EStorage.user);
  dispatch({
    type: EUserType.logout,
  });
};
